<template>

  <b-card>

    <div>
      <validation-observer
        ref="infoRules"
        v-slot="{ invalid }"
        tag="form"
      >
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="engineerStatusForm.name"
                trim
                placeholder="Name"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="engineerStatusForm.email"
                type="email"
                trim
                placeholder="Email"
                readonly
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">

            <validation-provider
              #default="validationContext"
              name="Main Certificate"
              rules="required"
            >
              <b-form-select
                v-model="selectedGroupId"
                :options="certificatesGroups"
                placeholder="Select Main Certificate You want To Edit this Statuses."
                class="d-inline-block w-100"
                :state="getValidationState(validationContext)"
                @change="onGroupChange"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>

          <!-- v-if="certificates.length" -->
          <b-col
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Main Certificate"
              rules="required"
            >
              <b-form-select
                v-model="selectedCertificateId"
                :options="certificates"
                placeholder="Select Sub Certificate You want To Edit this Statuses."
                class="d-inline-block w-100"
                :state="getValidationState(validationContext)"
                @input="onCertificateChange"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>

        </b-row>
        <b-row>
          <b-col
            v-if="showSelectedStatuses"
            md="6"
            class="mt-2"
          >

            <b-form-group
              label="Status "
              label-for="Status"
            >
              <b-form-checkbox-group
                id="status_id_cer"
                v-model="selectedStatuses"
                class="d-flex flex-column"
                :options="statusesCer"
                trim
                placeholder="Status"
                multiple
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="showSelectedStatuses"
            md="6"
            class="mt-2"
          >

            <b-form-group
              label=""
              label-for=""
            >
              <b-form-checkbox-group
                id="status_id_eng"
                v-model="selectedStatuses"
                class="d-flex flex-column"
                :options="statusesEng"
                trim
                multiple
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-if="!loaderStatus"
              variant="primary"
              class="mr-1"
              :disabled="invalid || Object.values(errors).length > 0"
              @click="editCertificateStatus"
            >
              Save Changes
            </b-button>
            <b-button
              v-if="loaderStatus"
              variant="primary"
              disabled
              class="mr-1"
            >
              <b-spinner
                small
                type="grow"
              />
              Loading...
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </div>
  </b-card>

</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  setup() {
    const { getValidationState } = formValidation()

    return {
      getValidationState,
    }
  },
  props: {
    certificatesGroups: {
      type: Array,
      default: () => [],
    },
    statusesEng: {
      type: Array,
      default: () => [],
    },
    statusesCer: {
      type: Array,
      default: () => [],
    },
    engineerStatusForm: {
      type: Object,
      default: () => ({}),
    },
    fetchDataCertificates: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      certificates: [],
      certificate: [],
      statuses: [],
      selectedGroupId: '',
      selectedCertificateId: '',
      selectedStatuses: [],
      showSelectedStatuses: false,
      loader: false,
      loaderBtn: false,
      loaderStatus: false,
      validationErrors: {},
      errors: {},
    }
  },
  watch: {
    '$route.params': {
      handler(newVal) {
        if (Number(newVal.id) !== Number(localStorage.getItem('RouterID'))) {
          localStorage.removeItem('selectedGroupId')
          localStorage.removeItem('selectedCertificateId')
        }
      },
      immediate: true,
      deep: true,
    },
  },

  mounted() {
    const LoaclItem = localStorage.getItem('selectedGroupId')
    // console.log(LoaclItem)
    if (LoaclItem) {
      this.selectedGroupId = LoaclItem
      /* eslint-disable eqeqeq */
      const group = this.certificatesGroups.filter(cert => cert.value == LoaclItem)
      if (group.length > 0 && group[0].certificates) {
        this.certificates = group[0].certificates.map(item => ({
          value: item.id,
          text: `${item.name.ar} - ${item.name.en}`,
          statuses: item.statuses,
        }))
        const selectedCertificateIdLoacal = localStorage.getItem('selectedCertificateId')
        this.selectedCertificateId = Number(selectedCertificateIdLoacal)
      } else {
        this.certificates = []
      }
    }
  },
  methods: {
    onGroupChange(value) {
      this.showSelectedStatuses = false
      const group = this.certificatesGroups.find(g => g.value === this.selectedGroupId)
      this.certificates = group ? group.certificates.map(item => ({
        value: item.id,
        text: `${item.name.ar} - ${item.name.en}`,
        statuses: item.statuses,
      })) : []

      this.selectedCertificateId = ''
      localStorage.setItem('RouterID', this.$route.params.id)
      localStorage.setItem('selectedGroupId', value)
    },
    onCertificateChange(value) {
      this.certificate = this.certificates.find(c => c.value === this.selectedCertificateId)
      if (this.certificate) {
        this.showSelectedStatuses = true

        const defaultStatus = this.certificate.statuses.map(s => s.status_id)
        // console.log(defaultStatus)
        this.selectedStatuses = defaultStatus
      }
      localStorage.setItem('selectedCertificateId', value)
    },

    async editCertificateStatus() {
      try {
        this.loaderStatus = true
        const payload = {
          data: this.selectedStatuses.map(status => ({
            user_id: this.$route.params.id,
            certificate_id: this.selectedCertificateId,
            status_id: status,
          })),
        }
        await axios
          .post('engineer-certificate-status/assign', payload)
          .then(res => {
            if (res.status === 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Updated Successfully',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.fetchDataCertificates()
            }
          })
      } catch (error) {
        if (error.response && error.response.status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${error.response.data.message}`,
              icon: 'BellIcon',
              variant: 'error',
            },
          })
        }
      } finally {
        this.loaderStatus = false
      }
    },

  },
}
</script>
