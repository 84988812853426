<template>
  <div>
    <!-- Status Certificates -->
    <CertificateCard
      title="Status Certificates"
      :is-visible="isVisable.certificatesStatus"
      :loader="loader"
      @toggle-edit="toggleEdit('certificatesStatus')"
    >
      <CertificatesStatus
        :certificates-groups="certificatesGroups"
        :statuses-eng="statusesEng"
        :statuses-cer="statusesCer"
        :engineer-status-form="engineerStatusForm"
        :fetch-data-certificates="fetchDataCertificates"
      />
    </CertificateCard>

    <!-- Permission Certificates -->
    <CertificateCard
      title="Permission Certificates"
      :is-visible="isVisable.permissionCertificates"
      :loader="loader"
      @toggle-edit="toggleEdit('permissionCertificates')"
    >
      <PermissionCertificates
        :engineer-status-form="engineerStatusForm"
        :options-status="optionsStatus"
        :options-allocation-sources="optionsAllocationSources"
        :fetch-data-certificates="fetchDataCertificates"
      />
    </CertificateCard>

    <!-- Work Certificates -->
    <CertificateCard
      title="Work Certificates"
      :is-visible="isVisable.workCertificates"
      :loader="loader"
      @toggle-edit="toggleEdit('workCertificates')"
    >
      <WorkCertificates
        :all-data-cer-status="AllDataCerStatus"
        :engineer-form="engineerForm"
        :options-status-id="optionsStatusId"
        :all-certificates="allCertificates"
        :selected-certificate-groups="selectedCertificateGroups"
        :fetch-data-certificates="fetchDataCertificates"
      />
    </CertificateCard>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import axios from 'axios'
import CertificatesStatus from './status-working-certificates/CertificatesStatus.vue'
import WorkCertificates from './status-working-certificates/WorkCertificates.vue'
import PermissionCertificates from './status-working-certificates/PermissionCertificates.vue'
import CertificateCard from './status-working-certificates/CertificateCard.vue'
import decryptData from '@/store/auth/decryptData'

export default {
  components: {
    CertificateCard,
    WorkCertificates,
    CertificatesStatus,
    PermissionCertificates,
  },
  setup() {
    const engineerStatusForm = ref({
      name: '',
      email: '',
      status_id: [],
    })
    const engineerForm = ref({
      name: '',
      email: '',
      certificates: [],
      subCertificates: {},
    })

    return {
      engineerStatusForm,
      engineerForm,
    }
  },
  data() {
    return {
      loader: false,
      isVisable: {
        certificatesStatus: false,
        workCertificates: false,
        permissionCertificates: false,
      },
      certificatesGroups: [],
      statusesEng: [],
      statusesCer: [],
      AllDataCerStatus: {},
      allCertificates: [],
      optionsStatus: [],
      optionsStatusId: [],
      optionsAllocationSources: [],
    }
  },
  computed: {
    selectedCertificateGroups() {
      return this.engineerForm.certificates
        .map(cert => {
          const group = this.allCertificates.find(g => g.value === cert)
          if (group) {
            return {
              ...group,
              certificates: group.certificates.map(certif => ({
                value: certif.id,
                text: `${certif.name.ar} - ${certif.name.en}`,
                span: `${this.AllDataCerStatus[certif.id]?.join(' - ') ?? ''}`,
              })),
            }
          }
          return null
        })
        .filter(group => group !== null)
    },
  },
  mounted() {
    this.fetchDataCertificates()
  },
  methods: {
    toggleEdit(section) {
      Object.keys(this.isVisable).forEach(key => {
        this.isVisable[key] = key === section ? !this.isVisable[key] : false
      })
    },
    async fetchDataCertificates() {
      try {
        this.loader = true
        await Promise.all([this.getEngineerStatus(), this.getCertificatesGroups(), this.getStatusesGroups(), this.showEngineer(), this.getEngineer()])
      } finally {
        this.loader = false
      }
    },

    async getEngineerStatus() {
      const res = await axios.get('certificate')
      this.allCertificates = decryptData(res.data.payload).data.groups.map(item => ({
        value: item.name.ar,
        text: `${item.name.ar} - ${item.name.en}`,
        certificates: item.certificates,
      }))
      this.optionsStatusId = this.allCertificates.map(item => ({
        value: item.value,
        text: item.text,
      }))
    },
    async getCertificatesGroups() {
      const response = await axios.get(`engineer/${this.$route.params.id}`)
      if (response.status === 200) {
        const userData = decryptData(response.data.payload).data.engineer.certificates_groups
        this.certificatesGroups = userData.map(item => ({
          value: item.id,
          text: `${item.name.ar} - ${item.name.en}`,
          certificates: item.certificates,
        }))

        this.engineerForm.certificates = userData.map(item => item.name.ar)
        userData.forEach(item => {
          const subCerts = item.certificates.map(cert => cert.id)
          const statuses = item.certificates.map(cert => ({
            id: cert.id,
            statuses: cert.statuses.map(status => status.status.status),
          }))
          statuses.forEach(status => {
            this.AllDataCerStatus[status.id] = status.statuses
          })
          // console.log(statuses)
          this.$set(this.engineerForm.subCertificates, item.name.ar, subCerts)
        })
      }
    },
    async getStatusesGroups() {
      const response = await axios.get('/certificate-status')
      if (response.status === 200) {
        const statusesData = decryptData(response.data.payload).data.status.map(item => ({
          value: item.id,
          text: item.status,
        }))
        this.statusesEng = statusesData.filter(status => [2, 10, 9, 5].includes(status.value))
        this.statusesCer = statusesData.filter(status => ![2, 10, 9, 5].includes(status.value))
        this.statuses = [...this.statusesEng, ...this.statusesCer]
      }
    },
    async  showEngineer() {
      if (this.$route.params.id) {
        const response = await axios.get(`/register/site/user/${this.$route.params.id}`)

        if (response.status === 200) {
          const userData = decryptData(response.data.payload).data.user
          this.engineerStatusForm.name = userData.name
          this.engineerStatusForm.email = userData.email
          this.engineerStatusForm.status_id = userData.status.map(item => item.id)
          this.engineerStatusForm.allocation_sources = Array.isArray(userData.allocation_sources) ? userData.allocation_sources.map(item => item) : []
        }
      }
    },
    async getEngineer() {
      const res = await axios.get('engineer-status')

      const decryptedData = decryptData(res.data.payload)
      this.optionsStatus = decryptedData.data.status.map(item => ({
        value: item.id,
        text: item.status,
      }))

      this.optionsAllocationSources = [
        { value: 'saber', text: 'Saber' },
        { value: 'jeem1', text: 'Jeem 1' },
        { value: 'sfda', text: 'SFDA' },
        { value: 'moiat', text: 'Moiat' },
        { value: 'sls', text: 'SLS' },
        { value: 'qfda', text: 'QFDA' },
      ]
    },

  },
}
</script>
