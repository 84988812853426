<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Certificates"
            rules="required"
          >
            <b-form-group
              label="Certificates"
              label-for="certificates"
            >
              <b-form-checkbox-group
                id="certificates_id"
                v-model="engineerForm.certificates"
                class="d-flex flex-column"
                :options="optionsStatusId"
                :state="getValidationState(validationContext)"
                multiple
                @change="uncheckedCertificates"
              />
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-for="(group, index) in selectedCertificateGroups"
          :key="index"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            :name="`subCertificates${index}`"
            rules="required"
          >
            <b-form-group
              :label="`Sub Certificates for ${group.text}`"
              :label-for="`sub_certificates_${index}`"
            >
              <b-form-checkbox-group
                :id="`sub_certificates_${index}`"
                v-model="engineerForm.subCertificates[group.value]"
                class="d-flex flex-column"
                :state="getValidationState(validationContext)"
                multiple
              >
                <b-form-checkbox
                  switch
                  @change="handelValue($event , group)"
                >
                  <span class="font-weight-bold text-xl"> Select ALL</span>
                </b-form-checkbox>
                <div
                  v-for="certificate in group.certificates"
                  :key="certificate.value"
                  class="d-flex align-items-start flex-column"
                >
                  <b-form-checkbox :value="certificate.value">
                    {{ certificate.text }}
                  </b-form-checkbox>
                  <span
                    v-if="certificate.span"
                    style="color: #3c9ade; margin-left: 8px;"
                  >Status: [{{ certificate.span }}]</span>
                </div>
              </b-form-checkbox-group>
            </b-form-group>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row v-if="Object.values(errors).length > 0">
        <b-col cols="12">
          <p
            v-for="(error, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error }}
          </p>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader"
            variant="primary"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addUserSite"
          >
            Save Changes
          </b-button>
          <b-button
            v-else
            variant="primary"
            disabled
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import decryptData from '@/store/auth/decryptData'

export default {
  props: {
    engineerForm: {
      type: Object,
      required: true,
    },
    AllDataCerStatus: {
      type: Object,
      required: true,
    },
    allCertificates: {
      type: Array,
      required: true,
    },
    optionsStatusId: {
      type: Array,
      required: true,
    },
    selectedCertificateGroups: {
      type: Array,
      required: true,
    },
    fetchDataCertificates: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loader: false,
      errors: {},
      loaderStatus: false,
      id: this.$store.state.generalIds.id,
      optionsStatus: [],
      optionsAllocationSources: [],
    }
  },

  setup() {
    const { getValidationState } = formValidation()

    return {
      getValidationState,
    }
  },
  methods: {
    handelValue(value, group) {
      if (value.length === 1) {
        this.engineerForm.subCertificates[group.value] = group.certificates.map(item => item.value)
      } else {
        this.engineerForm.subCertificates[group.value] = []
      }
    },
    uncheckedCertificates(checked) {
      const removedCertificates = Object.keys(this.engineerForm.subCertificates).filter(cert => !checked.includes(cert))

      removedCertificates.forEach(cert => {
        this.$delete(this.engineerForm.subCertificates, cert)
      })

      this.engineerForm.certificates = checked
    },
    addUserSite() {
      this.loader = true
      const formData = new FormData()

      Object.keys(this.engineerForm.subCertificates).forEach(key => {
        this.engineerForm.subCertificates[key].forEach(subCert => {
          formData.append('certificates[]', subCert)
        })
      })

      axios
        .post(`/engineer/${this.$route.params.id}/certificate`, formData)
        .then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Updated Successfully',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.fetchDataCertificates()
          }
        })
        .catch(error => {
          if (error.response.status) {
            const payload = decryptData(error.response.data.payload).message
            const firstArrayKey = Object.keys(payload).find(key => Array.isArray(payload[key]))
            const errorMessage = firstArrayKey ? payload[firstArrayKey][0] : 'An unknown error occurred'
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMessage,
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        })
        .finally(() => {
          this.loader = false
        })
    },

  },
}
</script>
