<template>
  <b-card
    class="readonlyDiv"
    :style="{ opacity: isVisible ? '1' : '0.7' }"
  >
    <div
      v-if="loader"
      class="text-center my-2"
    >
      <b-spinner
        type="grow"
        label="Loading..."
      />
    </div>
    <span v-else>
      <div class="have-icon">
        <h1>{{ title }}</h1>
        <feather-icon
          v-if="!isVisible"
          icon="EditIcon"
          class="cursor-pointer"
          size="25"
          @click="$emit('toggle-edit')"
        />
        <feather-icon
          v-else
          icon="XSquareIcon"
          class="cursor-pointer"
          size="25"
          @click="$emit('toggle-edit')"
        />
      </div>
      <div :style="{ pointerEvents: isVisible ? 'auto' : 'none' }">
        <slot />
      </div>
    </span>
  </b-card>
</template>

<script>
export default {
  props: {
    title: String,
    isVisible: Boolean,
    loader: Boolean,
  },
}
</script>

  <style lang="scss">
  .readonlyDiv {
    opacity: 0.7;
    transition: opacity 0.3s ease;
    .have-icon {
      opacity: 1 !important;
      color: black;
      display: flex;
      justify-content: space-between;
    }
  }
  </style>
